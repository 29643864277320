import { getLocalLabels } from '../../services/datahandler';
import { Remove } from './Remove';
export const Inputfilter = ({ onChange,removeFilter }) => {
    const labels = getLocalLabels();
    
    return (
        <div className="single-filter my-2">
        <div className="badge bg-secondary text-start flex-row d-flex p-2">
          
            <input 
            className="form-control form-control-md" 
            type="text" 
            placeholder="Stichwortfilter"
            onChange={e => onChange(e.target.value)}
            ></input>

<Remove onClick={removeFilter}/>
        
        </div>
        
        
        </div>       
        
    )
}